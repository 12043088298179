
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      form: {
        email: JwtService.getUsername(),
        clave: JwtService.getPassword(),
      },
    };
  },
  inject: ['messaging'],
  mounted () {
    console.log('Firebase cloud messaging object')
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loginErrorCount = ref(0);
    const submitButton = ref<HTMLButtonElement | null>(null);

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-login");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-login");
    });

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .required("Email es requerido")
        .email("El email no es válido")
        .label("Email"),
      password: Yup.string()
        .min(4)
        .required("Contraseña es requerida")
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            JwtService.saveUsername(values.email);
            JwtService.savePassword(values.password);
            if (store.getters.currentUser.first_access) {
                router.push({
                  name: "terms",
                  params: { token: store.getters.currentUser.refreshToken },
                });
              } else {
            router.push({ name: "dashboard" });
              }
          })
          .catch(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            loginErrorCount.value++;
            const [error] = Object.keys(store.getters.getAuthErrors);
            Swal.fire({
              text: store.getters.getAuthErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intenar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(function () {
              if (store.getters.currentUser.first_access) {
                router.push({
                  name: "onboarding",
                  params: { token: store.getters.currentUser.refreshToken },
                });
              }
            });
          });
      }, 0);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      loginErrorCount
    };
  },
});
